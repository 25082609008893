<template>
  <section class="page-container">
    <div class="head-body">
      <div class="head-body list">
        <div class="head-item">
          <span>今日订单：</span>
          <span class="data-s">{{ statistics.today.orderNum }}</span>
        </div>
        <div class="head-item">
          <span>完成订单：</span>
          <span class="data-s">{{ statistics.today.doneOrderNum }}</span>
        </div>
        <div class="head-item">
          <span>今日收入：</span>
          <span class="data-s">{{ statistics.today.income }}</span>
        </div>
        <div class="head-item">
          <span>今日实际收入：</span>
          <span class="data-s">{{ statistics.today.actualIncome }}</span>
        </div>
      </div>
      <div class="head-body list">
        <div class="head-item">
          <span>昨日订单：</span>
          <span class="data-s">{{ statistics.yesterday.orderNum }}</span>
        </div>
        <div class="head-item">
          <span>昨日完成订单：</span>
          <span class="data-s">{{ statistics.yesterday.doneOrderNum }}</span>
        </div>
        <div class="head-item">
          <span>昨日收入：</span>
          <span class="data-s">{{ statistics.yesterday.income }}</span>
        </div>
        <div class="head-item">
          <span>昨日实际收入：</span>
          <span class="data-s">{{ statistics.yesterday.actualIncome }}</span>
        </div>
      </div>
    </div>
    <div>
      <el-form :inline="true">
        <el-form-item>
          <el-input v-model="selectOrderForm.orderId" placeholder="订单ID"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="selectOrderForm.orderNo" placeholder="订单号"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getOrderLst()"> 查询 </el-button>
        </el-form-item>
      </el-form>
      <el-table
        :data="orderList"
        v-loading = "loading"
        element-loading-background = "rgba(0, 0, 0, 0.5)"
        element-loading-text = "数据正在加载中"
        element-loading-spinner = "el-icon-loading"
        style="width: 100%">
        <el-table-column
          prop="orderNo"
          label="订单号">
        </el-table-column>
        <el-table-column
          label="创建时间">
          <template slot-scope="scope">
            {{ scope.row.createTime | formatDate }}
          </template>
        </el-table-column>
        <el-table-column
          label="商品">
          <template slot-scope="scope">
            <span>{{ scope.row.commodity[0].commodityName }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="payment"
          label="支付方式">
        </el-table-column>
        <el-table-column
          label="状态">
          <template slot-scope="scope">
            <span v-if="scope.row.status === 0">待支付</span>
            <span v-if="scope.row.status === 1">已完成</span>
            <span v-if="scope.row.status === 2">已关闭</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="orderTotalPrice"
          label="订单总价">
        </el-table-column>
        <el-table-column
          label="操作">
          <template slot-scope="scope">
            <el-button v-if="scope.row.status != 1" type="text" @click="supplement(scope.row.orderNo)">手动补单</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div v-if="selectOrderForm.total > 0" ref="pagebar" class="pagebar">
        <el-pagination
          :page-sizes="[5,10,20, 50, 100, 300]"
          :current-page="selectOrderForm.page"
          :page-size="selectOrderForm.limit"
          :total="selectOrderForm.total"
          layout="total, sizes, prev, pager, next, jumper"
          @current-change="handlePageChange"
          @size-change="handleLimitChange"/>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      loading:false,
      statistics:{},
      orderList:[],
      selectOrderForm:{
        page:1,
        limit:10,
        total:0,
        orderId:'',
        orderNo:'',
        startDate:'',
        endDate:'',
      }
    }
  },
  mounted() {
    this.getStatistics()
    this.getOrderLst()
  },
  methods: {
    async getStatistics(){
      try{
        this.statistics = await this.axios.get("/api/admin/order/statistics")
      }catch (e){
        this.$notify.error({ title: "错误", message: e.message });
      }
    },
    async getOrderLst(){
      try{
        this.loading = true
        let order = await this.axios.form("/api/admin/order/list",this.selectOrderForm)
        this.orderList = order.order
        this.selectOrderForm.total = order.page.total
        this.selectOrderForm.page = order.page.page
        this.selectOrderForm.limit = order.page.limit
      }catch (e){
        this.$notify.error({ title: "错误", message: e.message });
      }
      this.loading = false
    },
    async supplement(orderNo){
      try{
        this.loading = true
        await this.axios.form("/api/admin/order/supplement/"+orderNo)
      }catch (e){
        this.$notify.error({ title: "错误", message: e.message });
      }
      this.loading = false
    },
    handlePageChange(val){
      this.selectOrderForm.page = val
      this.getOrderLst()
    },
    handleLimitChange(val){
      this.selectOrderForm.limit = val
      this.getOrderLst()
    }
  }
}
</script>

<style scoped lang="scss">
.head-body{
  display: flex;
  justify-content: space-between;
  &.list{
    width: 49%;
    height: 300px;
    display: flex;
    justify-content: space-between;
    flex-wrap:wrap;
  }
  .head-item{
    width: 49%;
    height: 140px;
    background-color: rgb(250, 250, 250);
    padding: 10px;
    display: flex;
    flex-direction: column;
    .data-s{
      color: red;
      font-size: 24px;
    }
  }
}

</style>
